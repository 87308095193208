import { ThemeOptions, createTheme } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#00517a',
    },
    secondary: {
      main: '#006699',
    },
    success: {
      main: '#8bb953',
    },
    error: {
      main: '#ca0000',
    },
  },
};

export default createTheme(themeOptions);
import {createAsyncThunk} from "@reduxjs/toolkit"
import {Catalog, Product, User} from "../../types"
import {
    getCountFromServer, getDocs, query,
    doc, getDoc, getFirestore, collection,
    orderBy, limit, startAfter
} from "firebase/firestore"
import app from "../../repository"
import {setMaxPrice} from "./index";

export const getUserData = createAsyncThunk<User, string>(
    'app/get',
    async (userID, { rejectWithValue }) => {
        const db = getFirestore(app)
        const docRef = doc(db, `/Miembros/${userID}`)

        try {
            const response = await getDoc(docRef)
            let data = {...response.data()}

            delete data.createdAt
            return data as User
        }catch (e:any) {
            return rejectWithValue({
                error: e.message
            })
        }
    }
)

export const getCatalogData = createAsyncThunk<Catalog, { userID: string, catalogID: string }>(
    'catalog/get',
    async (
    {userID, catalogID},
    { rejectWithValue, dispatch }
) => {
        const db = getFirestore(app)
        const docRef = doc(db, `/Miembros/${userID}/Catalogos/${catalogID}`)
        const collectionRef = collection(db, `/Miembros/${userID}/Catalogos/${catalogID}/Productos`)

        try {
            const response = await getDoc(docRef)
            const productSnap = await getCountFromServer(collectionRef)

            // Get max price for filtering
            const queryRef = query(collectionRef, orderBy("price", "desc"), limit(1))
            const products = await getDocs(queryRef)
            const product = products.docs[0] ? products.docs[0].data():{ price: 0 }
            dispatch(setMaxPrice(product))

            const catalog = response.data() as Catalog

            // Set tab title
            document.title = catalog.name

            return {
                ...catalog,
                totalProducts: productSnap.data().count
            }
        }catch (e:any) {
            return rejectWithValue({
                error: e.message
            })
        }
    }
)

export const getProductsBatch = createAsyncThunk<{ products:Product[], lastRef:any }, { userID:string, catalogID:string, size?: number, lastRef:any  }>(
    'products/batch/get',
    async ({userID, catalogID, size = 12, lastRef}, { rejectWithValue, getState, dispatch:dispatcher }) => {

        const db = getFirestore(app)
        const productsRef = collection(db, `/Miembros/${userID}/Catalogos/${catalogID}/Productos`)

        try {
            let batchRef = lastRef !== null
                ? query(productsRef, orderBy("name"), startAfter(lastRef), limit(size))
                : query(productsRef, orderBy("name"), limit(size))
            const batch = await getDocs(batchRef)

            const products:Product[] = []
            for(const doc of batch.docs){
                const product = doc.data() as Product
                product.id = doc.id
                products.push(product)
            }

            return {
                products,
                lastRef: batch.docs[batch.docs.length-1]
            }
        }catch (e:any) {
            console.log("Error", e)
            return rejectWithValue({
                error: e.message
            })
        }
    }
)

export const getProduct = createAsyncThunk<Product, { userID:string, catalogID:string, productID: string}>(
    'product/get',
    async ({userID, catalogID, productID}, { rejectWithValue }) => {

        const db = getFirestore(app)
        const productRef = doc(db, `/Miembros/${userID}/Catalogos/${catalogID}/Productos/${productID}`)

        try {
            const productDoc = await getDoc(productRef)
            const product = productDoc.data() as Product
            product.id = productDoc.id

            return product
        }catch (e:any) {
            console.log("Error", e)
            return rejectWithValue({
                error: e.message
            })
        }
    }
)

export const getImage = createAsyncThunk<any, { userID:string, catalogID:string, productID:string, imageID:string }>(
'image/get',
async ({
    userID,
    catalogID,
    productID,
    imageID,
}, { rejectWithValue}) => {
    const db = getFirestore(app)
    const imageRef = doc(db, `/Miembros/${userID}/Catalogos/${catalogID}/Productos/${productID}/Images/${imageID}`)

    try {
        const result = await getDoc(imageRef)
        return result.data()
    }catch (e:any) {
        console.log("Error", e)
        return rejectWithValue({
            error: e.message
        })
    }
})
import {useParams} from "react-router-dom";
import {Box, CircularProgress, Theme, useMediaQuery} from "@mui/material";
import {FixedSizeGrid as GridVirtualized} from 'react-window'
import AutoSizer from "react-virtualized-auto-sizer";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import ProductCell from "./productcell";
import {useAppDispatch, useAppState} from "../../store/hooks";
import {collection, getDocs, getFirestore, limit, orderBy, query, startAfter} from "firebase/firestore";
import app from "../../repository";
import {Product} from "../../types";
import {addProducts, restoreCart} from "../../features/app";
import ProductDetail from "../dialogs/productdetail";
import Ca7Loader from "../loader";

// const StoreCover = styled('img')(({ theme }) => ({
//     display: 'block',
//     width: '100%'
// }));

const Store = () => {
    const { userID, catalogID } = useParams()

    const [isLoading, toggleLoading] = useState(false)
    const loadDelay = useRef<any|null>(null)
    const lastProduct = useRef<any|null>(null)
    const isLoadingProducts = useRef(false)

    const lg = useMediaQuery((theme:Theme) => theme.breakpoints.up('lg'))
    const sm = useMediaQuery((theme:Theme) => theme.breakpoints.up('sm'))
    const xs = useMediaQuery((theme:Theme) => theme.breakpoints.up('xs'))

    const dispatcher = useAppDispatch()

    const {
        ids: productIndex,
        filteredIds: filteredIndex,
        hasMoreProducts
    } = useAppState((state) => state.app.products, (prev, curr) =>
        prev.ids.length === curr.ids.length &&
        prev.filteredIds?.length === curr.filteredIds?.length &&
        prev.hasMoreProducts === curr.hasMoreProducts
    )

    const catalogCurrency = useAppState((state) => state.app.catalog?.value?.currency)

    const columnCount = useMemo(() => {
        if(lg) return 4
        if(sm) return 3
        return 2
    }, [xs, sm, lg])

    const rowHeight = useMemo(() => {
        if(sm) return 320
        return 305
    }, [xs, sm, lg])

    const rowCount = useMemo(() => {
        if(filteredIndex !== null) return Math.ceil(filteredIndex.length / columnCount)
        return Math.ceil(productIndex.length / columnCount)
    }, [columnCount, productIndex.length, filteredIndex, hasMoreProducts])

    const getProducts = async (userID:string, catalogID:string, size:number = 12) => {
        const db = getFirestore(app)
        const productsRef = collection(db, `/Miembros/${userID}/Catalogos/${catalogID}/Productos`)

        try {
            let batchRef
            if(Boolean(lastProduct.current) && !filteredIndex){
                batchRef = query(productsRef, orderBy("name"), startAfter(lastProduct.current), limit(size))
            }else if(Boolean(lastProduct.current) && filteredIndex){
                batchRef = query(productsRef, orderBy("name"), startAfter(lastProduct.current))
            }else {
                batchRef = query(productsRef, orderBy("name"), limit(size))
            }

            const batch = await getDocs(batchRef)

            const products:Product[] = []
            for(const doc of batch.docs){
                const product = doc.data() as Product
                product.id = doc.id
                products.push(product)
            }

            if(batch.docs[batch.docs.length - 1]){
                lastProduct.current = batch.docs[batch.docs.length - 1]
            }
            dispatcher(addProducts(products))
        }catch (e:any) {
            console.log("Error", e)
        }

        isLoadingProducts.current = false
        toggleLoading(false)
    }

    const loadMoreProducts = useCallback(({ visibleRowStopIndex }:any) => {
        if(visibleRowStopIndex === (rowCount-1)) {
            if(!isLoadingProducts.current && hasMoreProducts){
                if(userID && catalogID){
                    isLoadingProducts.current = true
                    toggleLoading(true)
                    clearTimeout(loadDelay.current)
                    loadDelay.current = setTimeout(
                        () => getProducts(userID, catalogID),
                        400
                    )
                }
            }
        }
    }, [filteredIndex, hasMoreProducts, rowCount, toggleLoading])

    useEffect(() => {
        loadMoreProducts({ visibleRowStopIndex: -1 })
        //dispatcher(restoreCart(catalogID))
    }, [])

    useEffect(() => {
        if(catalogID) dispatcher(restoreCart(catalogID))
    }, [catalogID])

    useEffect(() => {
        if(hasMoreProducts && filteredIndex && filteredIndex.length === 0){
            loadMoreProducts({ visibleRowStopIndex: rowCount-1 })
        }
    }, [hasMoreProducts, filteredIndex, loadMoreProducts, rowCount, isLoading])

    if(productIndex.length === 0 && hasMoreProducts)
    return <Ca7Loader />
    
    return <Box position='relative' display='flex' flexDirection='column' height='100%'>
        <ProductDetail />
        {/*<Box>*/}
        {/*    <Card elevation={4}>*/}
        {/*        <StoreCover src="https://pickbazar-react-rest.vercel.app/_next/image?url=https%3A%2F%2Fpickbazarlaravel.s3.ap-southeast-1.amazonaws.com%2F888%2FUntitled-3.jpg&w=3840&q=75" />*/}
        {/*    </Card>*/}
        {/*</Box>*/}

        <Box flexGrow={1} sx={{'& div div ::-webkit-scrollbar-button:start:decrement': { display:'none' }}}>
            <AutoSizer>
                {({ width, height }:any) =>
                    <GridVirtualized
                        className="fancy-scroll"
                        columnCount={columnCount}
                        rowCount={rowCount}
                        columnWidth={Math.floor(width/columnCount)-2}
                        itemData={{ columnSize: columnCount, currency: catalogCurrency }}
                        onItemsRendered={loadMoreProducts}
                        rowHeight={rowHeight}
                        width={width}
                        height={height}
                        style={{overflowX: 'hidden'}}
                    >
                        {ProductCell}
                    </GridVirtualized>
                }
            </AutoSizer>
        </Box>
        <Box
            visibility={isLoading ? 'visible':'hidden'}
            bottom={-10}
            position='absolute'
            left='50%'
            sx={{
                display: 'flex',
                transform: 'translateX(-50%)',
                borderRadius: '50%',
                backgroundColor: 'var(--secondary-bgcolor)',
                width: 47, height: 47,
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '35px'
            }}
        >
            <CircularProgress />
        </Box>
    </Box>
}

export default Store;
import {Box, Button, Card, CardContent, CardHeader, Chip, Dialog, IconButton, Typography} from "@mui/material"
import {useAppDispatch, useAppState} from "../../../store/hooks"
import {toggleProductDialog} from "../../../features/app"
import {Slide} from 'react-slideshow-image'
import Grid from '@mui/material/Unstable_Grid2';
import 'react-slideshow-image/dist/styles.css'
import Image from "../../store/image";
import {useParams} from "react-router-dom";
import {useMemo} from "react";
import Discount from "../../store/discount";
import {useTranslation} from "react-i18next";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import {StockColor} from "../../../types";
import Cart from "../../store/cart";

const ProductDetail = () => {

    const {userID, catalogID} = useParams()
    const {id, isDetailOpen: isOpen} = useAppState((state) => state.app.gui)
    const product = useAppState((state) => id ? state.app.products.entities[id] : undefined)
    const catalogCurrency = useAppState((state) => state.app.catalog?.value?.currency)
    const { t } = useTranslation()
    const dispatcher = useAppDispatch()

    const finalPrice = useMemo(() => {
        if(!product) return ""
        if(product.discount > 0){
            return (product.price - (product.price*product.discount/100)).toFixed(2)
        }else{
            return product.price.toFixed(2)
        }
    }, [product?.discount, product?.price])

    const closeDialog = () => {
        dispatcher(toggleProductDialog(id))
    }

    return <Dialog
        open={isOpen}
        onClose={closeDialog}
        maxWidth={'md'}
    >
        {id && product && userID && catalogID ?
            <Card sx={{overflow: 'auto'}}>
                <CardContent>
                    <Box position='absolute' zIndex={999} top={0} left={0} sx={{display: {xs: 'block', sm: 'none'}}}>
                        <IconButton size='large' onClick={closeDialog}>
                            <CancelRoundedIcon fontSize='large'/>
                        </IconButton>
                    </Box>

                    <Grid container sx={{minWidth: { sm: 500, lg: 800 }}}>
                        <Grid
                            position='relative'
                            xs={12} sm={8} mb={2}
                            sx={{
                                '& .product-picture': {
                                    minWidth: { xs: 'unset', sm: 500 },
                                    height: { xs: 300, sm: 500, lg: 600 }
                                },
                                '& .product-picture.dummy-icon': {
                                    minWidth: { xs: 300, sm: 400, md: 400, lg: 500 },
                                    height: { xs: 300, sm: 500, lg: 600 }
                                },
                                backgroundColor: 'rgba(0,0,0, 0.05)',
                                borderRadius: '0.5rem'
                            }}
                        >
                            <Discount discount={product.discount} size='medium' top={15} right={15} />
                            {product.picturesRef.length === 0 ?
                                <Image
                                    key={product.id}
                                    userID={userID}
                                    catalogID={catalogID}
                                    productID={product.id}
                                    imageID={'dummy'}
                                    dummy
                                />:
                                <Slide
                                    autoplay={false}
                                    transitionDuration={400}
                                >
                                    {
                                        product.picturesRef.map((imageID) => {
                                            return <Image
                                                key={imageID}
                                                userID={userID}
                                                catalogID={catalogID}
                                                productID={product.id}
                                                imageID={imageID}
                                            />
                                        })
                                    }

                                </Slide>
                            }
                        </Grid>
                        <Grid xs={12} sm={4} paddingX={2} paddingY={1}>
                            <Box display='flex' position='relative'>
                                <Typography variant='h4'>
                                    {product.name}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='body1' my={2}>
                                    {product.description}
                                </Typography>
                            </Box>
                            <Box display='flex' alignItems='center' flexDirection='row'>
                                <Typography variant='body1' fontWeight='bold'>
                                    Stock:
                                </Typography>&nbsp;
                                <Chip label={t(`stock.${product.stock}.label`)} color={StockColor[product.stock]} />
                            </Box>
                            <Box>
                                <Typography variant='caption'>
                                    {t(`stock.${product.stock}.desc`)}
                                </Typography>
                            </Box>

                            <Box display='flex' flexDirection='row' alignItems='center' my={2}>
                                <Typography
                                    variant='h4'
                                    fontWeight='bold'
                                    sx={(t)=>({
                                        color: t.palette.primary.main
                                    })}
                                >
                                    {catalogCurrency} {finalPrice}
                                </Typography>
                                {product.discount > 0.0 ?
                                    <Typography
                                        variant='body2'
                                        fontWeight='bold'
                                        color='text.secondary'
                                        sx={{textDecoration: 'line-through', mr: 1}}
                                    >
                                        {catalogCurrency} {product.price.toFixed(2)}
                                    </Typography>:null
                                }

                            </Box>

                            <Cart
                                id={product.id}
                                stock={product.stock}
                                size='large'
                                variant='contained'
                                hideOnRunOut
                                showOpenCartBtn
                            />
                        </Grid>

                    </Grid>

                </CardContent>
            </Card>
            : null
        }
    </Dialog>
}

export default ProductDetail
import {Box, useMediaQuery, styled, Theme, Drawer} from "@mui/material"
import Sidebar from "../sidebar"
import { ReactNode } from "react"
import CartPreview from "../cartpreview";
import Cart from "../cart";

const LayoutBox = styled(Box)(({}) => ({
    display: 'flex',
    boxSizing: 'border-box',
    height: '100%',
    maxWidth: '1300px',
    margin: 'auto'
}))

type Props = { children: ReactNode }
const Layout = ({children}:Props) => {

    //const theme = useTheme()
    const collapseSidebar = useMediaQuery((theme:Theme) => theme.breakpoints.up('md'))

    return <LayoutBox flexDirection={ collapseSidebar ? 'row':'column'}>
        <Box boxSizing='border-box' height={ collapseSidebar ? '100%': 'auto'} px={2} pt={2} pb={collapseSidebar ? 2:0}>
            <Sidebar expanded={collapseSidebar} />
        </Box>
        <Box boxSizing='border-box' flexGrow={1} height={'100%'} maxHeight={'100%'} overflow={'auto'} padding={'1rem'}>
            {children}
        </Box>
        <Cart />
        {!collapseSidebar
            ? <CartPreview />
            : null
        }
    </LayoutBox>
}

export default Layout
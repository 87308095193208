import {GridChildComponentProps} from "react-window";
import {Box, Button, ButtonGroup, Card, CardContent, CardMedia, Typography} from "@mui/material";
import {useAppDispatch, useAppState} from "../../../store/hooks";
import Discount from "../discount";
import Price from "../price";
import {memo, useEffect, useMemo, useState} from "react";
import {getImage} from "../../../features/app/extra-reducers";
import {useParams} from "react-router-dom";
import {toggleProductDialog} from "../../../features/app";
import Cart from "../cart";

const ProductCell = ({
    columnIndex,
    rowIndex,
    style,
    data
}: Omit<GridChildComponentProps, "data"> & { data: any }) => {

    const { userID, catalogID } = useParams()
    const product = useAppState((state) => {
        const pos = rowIndex*data.columnSize+columnIndex
        const id = state.app.products.filteredIds ? state.app.products.filteredIds[pos] : state.app.products.ids[pos]

        if(id) return state.app.products.entities[id]
        return null
    })

    const dispatcher = useAppDispatch()

    const cover = useMemo(() => {
        if(product){
            if(product.picturesRef.length > 0){
                const imageID = product.picturesRef[0]
                if(product.pictures[imageID]){
                    if(product.pictures[imageID].onlinePath && product.pictures[imageID].onlinePath !== ""){
                        return product.pictures[imageID].onlinePath
                    }
                }
            }
        }

        return '/design/single_product.svg'
    }, [product?.picturesRef, product?.pictures])

    useEffect(() => {
        if(userID && catalogID){
            if(product && product.picturesRef.length > 0){
                const imageID = product.picturesRef[0]
                const productID = product.id
                if(!product.pictures[imageID]){
                    dispatcher(getImage({
                        userID,
                        catalogID,
                        productID,
                        imageID
                    }))
                }
            }
        }
    }, [product?.picturesRef, product?.pictures])

    return <Box
        style={style}
        sx={{p: 1, pt: rowIndex === 0 ? 0:1, boxSizing: 'border-box', cursor: product ? 'pointer':'default'}}
        onClick={() => {
            if(product) dispatcher(toggleProductDialog(product?.id))
        }}
    >
        {
            product !== null
                ? <Card sx={{height: '100%', position: 'relative'}}>
                    {/* Render your cell content here */}
                    <Discount discount={product.discount} />
                    <CardMedia
                        component="img"
                        height={160}
                        sx={{
                            boxSizing: 'border-box',
                            border: '5px solid transparent',
                            borderRadius: '0.5rem 0.5rem 0 0',
                            backgroundColor: 'var(--secondary-bgcolor)'
                        }}
                        image={cover}
                    />
                    <CardContent sx={{p: { xs: 1, sm: 2 }}}>
                        <Box>
                            <Typography
                                variant={"body1"}
                                fontWeight='bold'
                                component="div"
                                marginBottom={0}
                                title={product.name}
                                sx={{
                                    textWrap: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden'
                                }}
                            >
                                {product.name}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                component="div"
                                sx={{
                                    color: product.description.trim() == "" ? 'transparent':'unset',
                                    userSelect: product.description === "" ? 'none':'unset',
                                    textWrap: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden'
                                }}
                            >
                                {product.description !== "" ? product.description:"No description"}
                            </Typography>
                            <Price
                                price={product.price}
                                discount={product.discount}
                                currency={data.currency}
                            />
                        </Box>
                        <Cart id={product.id} stock={product.stock} size='small' variant='outlined' justify='end'/>
                    </CardContent>
                </Card>
                :null
        }
    </Box>
}

export default memo(ProductCell)
import { Route, Routes } from 'react-router-dom';
import Store from './components/store';
import Layout from './components/layout';
import GetIt from "./components/getit";
import DeleteAccount from "./components/deleteaccount";
import Terms from "./components/terms";

function App() {
  return <Routes>
    {/** Catalog default route */}
    <Route path='u/:userID/c/:catalogID' element={
      <Layout>
        <Store />
      </Layout>} 
    />
    <Route path="/deleteAccount" element={<DeleteAccount />} />
    <Route path="/Terms_Conditions/:locale" element={ <Terms /> } />
    <Route path='*' element={<GetIt />} />
  </Routes>
}

export default App;

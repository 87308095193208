import {configureStore} from "@reduxjs/toolkit";
import AppReducer from '../features/app'

export const store = configureStore({
    reducer: {
        app: AppReducer
    }
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
import {useEffect, useState} from "react";
import {useAppDispatch, useAppState} from "../../store/hooks";
import {getCatalogData, getProduct, getUserData} from "./extra-reducers";

export const useGetCatalogData = (userID?:string, catalogID?:string) => {
    const [error, setError] = useState<number|null>(null)
    const catalog = useAppState((state) => state.app.catalog)
    const dispatcher = useAppDispatch()


    useEffect(() => {
        if(userID && catalogID && !catalog && !error){
            dispatcher(getCatalogData({userID, catalogID}))
                .then((res:any) => {
                    if(res.error){
                        console.log("Error:", res)
                        setError(404)
                    }
                })
                .catch((err) => {
                    console.log("Failed:", err)
                })
        }
    }, [userID, catalogID, catalog, error])


    return {
        isLoading: !error && !catalog,
        isError: error,
        catalog: catalog?.value
    }
}

export const useGetUserData = (userID?:string) => {
    const [error, setError] = useState<number|null>(null)
    const user = useAppState((state) => state.app.user)
    const dispatcher = useAppDispatch()


    useEffect(() => {
        if(userID && !user && !error){
            dispatcher(getUserData(userID)).catch((err) => {
                console.log("Failed:", err)
            })
        }
    }, [userID, user, error])


    return {
        isLoading: !error && !user,
        isError: error,
        user: user?.value
    }
}

export const useGetProduct = (productID:string, userID?:string, catalogID?:string) => {
    const product = useAppState((state) => state.app.products.entities[productID])
    const dispatcher = useAppDispatch()

    useEffect(() => {
        if(!product && userID && catalogID){
            dispatcher(
                getProduct({
                    userID,
                    catalogID,
                    productID
                })
            )
        }
    }, [userID, catalogID, productID])

    return {
        product,
        isLoading: !product
    }
}
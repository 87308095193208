import {Box, Typography} from "@mui/material";
import {useMemo} from "react";


type Props = {
    currency: string
    discount: number
    price: number
}
const Price = ({
    currency,
    discount,
    price
}:Props) => {

    const finalPrice = useMemo(() => {
        if(discount > 0){
            return (price - (price*discount/100)).toFixed(2)
        }else{
            return price.toFixed(2)
        }
    }, [discount, price])

    return <Box>
        {discount > 0 ?
            <Typography
                variant='caption'
                color='text.secondary'
                sx={{
                    textDecoration: 'line-through',
                    marginBottom: -10
                }}
            >
                {currency}. {price.toFixed(2)}
            </Typography>
            :
            <Typography
                variant='caption'
                color='transparent'
                sx={{userSelection: 'none'}}
            >
                .
            </Typography>
        }
        <Typography
            variant='body1'
            sx={{marginTop: -1}}
        >
            {currency}. {finalPrice}
        </Typography>
    </Box>
}

export default Price
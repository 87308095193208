import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Catalog, Product, User} from "../../types";
import {getCatalogData, getImage, getProduct, getProductsBatch, getUserData} from "./extra-reducers";

export const StorageCartKey = "catalogo_cart"
type LazyValue<T> = {
    isLoading:boolean
    value: T|null
}
type AppReducer = {
    user: LazyValue<User>|null,
    catalog: LazyValue<Catalog>|null,
    products: {
        ids: string[],
        filteredIds: string[]|null,
        total: number,
        lastProduct: any|null,
        hasMoreProducts: boolean,
        entities: {
            [key:string]: Product
        }
    },
    gui: {
        id?: string,
        isDetailOpen: boolean,
        isCartOpen: boolean,
        isInfoOpen: boolean
    },
    cart: { [key: string]: number }
    cartRestored: boolean
    filters: {
        search: string
        onlyNonDiscounted: boolean
        onlyDiscounted: boolean
        stock: string | null
        price: {
            minPrice: number
            maxPrice: number
            value: number[] | null
        }
    }
}

const name = 'AppReducer'
const initialState:AppReducer = {
    user: null,
    catalog: null,
    products: {
        ids: [],
        filteredIds: null,
        total: 0,
        lastProduct: null,
        hasMoreProducts: true,
        entities: {}
    },
    gui: {
        isDetailOpen: false,
        isCartOpen: false,
        isInfoOpen: false
    },
    cartRestored: false,
    cart: {},
    filters: {
        search: '',
        onlyNonDiscounted: false,
        onlyDiscounted: false,
        stock: 'all',
        price: {
            minPrice: 0,
            maxPrice: 0,
            value: null
        }
    }
}
export const appSlice = createSlice({
    name,
    initialState,
    reducers: {
        setLastProductRef(state, { payload:productRef }:PayloadAction<any>){
            state.products.lastProduct = productRef
        },
        addProducts(state, { payload:products }:PayloadAction<Product[]>){
            for(const product of products){
                if(!state.products.entities[product.id]){
                    state.products.ids.push(product.id)
                }

                product.pictures = product.pictures ?? {}
                state.products.entities[product.id] = product
            }

            if(products.length < 12 || products.length > 12){
                state.products.hasMoreProducts = false
            }

            filterProducts(state)
        },
        toggleProductDialog(state, { payload }){
            state.gui.id = payload
            state.gui.isDetailOpen = !state.gui.isDetailOpen
        },
        toggleInfoDialog(state){
            state.gui.isInfoOpen = !state.gui.isInfoOpen
        },

        addToCart(state, { payload }:PayloadAction<string>){
            if(state.cart[payload]){
                state.cart[payload]++
            } else {
                state.cart[payload] = 1
            }

            saveCart(state.cart, state.catalog?.value?.uniqueID ?? "missing_id")
        },
        removeFromCart(state, { payload }:PayloadAction<string>){
            if(state.cart[payload]){
                if(state.cart[payload] > 1){
                    state.cart[payload]--
                }else{
                    delete state.cart[payload]
                }
            } else {
                state.cart[payload] = 1
            }
            saveCart(state.cart, state.catalog?.value?.uniqueID ?? "missing_id")
        },
        clearFromCart(state, {payload}:PayloadAction<string>){
            delete state.cart[payload]
            saveCart(state.cart, state.catalog?.value?.uniqueID ?? "missing_id")
        },
        restoreCart(state, { payload }:PayloadAction<string>){
            if(!state.cartRestored){
                state.cart = JSON.parse(localStorage.getItem(`${StorageCartKey}_${payload}`) ?? "{}")
                state.cartRestored = true
            }
        },

        toggleCartOpen(state, { payload }: PayloadAction<boolean>){
            state.gui.isCartOpen = payload ?? false
        },

        // Filters
        setMaxPrice(state, { payload}){
            state.filters.price.maxPrice = payload.price
        },
        setPriceFilter(state, {payload}){
            state.filters.price.value = payload

            filterProducts(state)
        },
        setStockFilter(state, {payload}){
            state.filters.stock = payload

            filterProducts(state)
        },
        setSearchFilter(state, {payload}){
            state.filters.search = payload

            filterProducts(state)
        },
        toggleDiscountedFilter(state, { payload }){
            if(payload) state.filters.onlyNonDiscounted = false
            state.filters.onlyDiscounted = payload

            filterProducts(state)
        },
        toggleNonDiscountedFilter(state, { payload }){
            if(payload) state.filters.onlyDiscounted = false
            state.filters.onlyNonDiscounted = payload

            filterProducts(state)
        },
        clearFilters(state){
            state.filters = {
                search: '',
                onlyNonDiscounted: false,
                onlyDiscounted: false,
                stock: 'all',
                price: {
                    minPrice: 0,
                    maxPrice: 0,
                    value: null
                }
            }
            state.products.filteredIds = null
        }
    },
    extraReducers: (builder) => { builder
        // User
        .addCase(getUserData.pending, (state) => {
            state.user = { isLoading: true, value: null }
        })
        .addCase(getUserData.rejected, (state) => {
            state.user = null
        })
        .addCase(getUserData.fulfilled, (state, { payload}) => {
            state.user = { isLoading: false, value: payload }
        })

        // Catalog
        .addCase(getCatalogData.pending, (state) => {
            state.catalog = { isLoading: true, value: null }
        })
        .addCase(getCatalogData.rejected, (state) => {
            state.catalog = null
        })
        .addCase(getCatalogData.fulfilled, (state, { payload}) => {
            state.catalog = { isLoading: false, value: payload }
        })

        // Products
        // .addCase(getProductsBatch.rejected, (state, { error, payload}) => {
        //     console.log("ActionR:", payload, error)
        // })
        .addCase(getProductsBatch.fulfilled, (state, { meta, type, payload: { products }}) => {
            for(const product of products){
                product.pictures = {}
                state.products.entities[product.id] = product
                state.products.ids.push(product.id)
            }

            if(products.length < 12){
                state.products.hasMoreProducts = false
            }
        })
        .addCase(getProduct.fulfilled, (state, { payload})=> {
            payload.pictures = {}
            state.products.entities[payload.id] = payload
        })

        // Images
        .addCase(getImage.pending, (state, { }) => {

        })
        .addCase(getImage.rejected, (state, { }) => {

        })
        .addCase(getImage.fulfilled, (state, { meta, payload }) => {
            const { productID, imageID } = meta.arg
            if(state.products.entities[productID]) {
                state.products.entities[productID].pictures[imageID] = payload
            }
        })

        // Cart

    }
})

const filterProducts = (state: any) => {

    state.products.filteredIds = state.products.ids.filter((id:string) => {
        const product = state.products.entities[id]

        if(
            state.filters.search.trim() !== '' &&
            !product.name.toUpperCase().includes(state.filters.search.trim().toUpperCase())
        ){
            return false
        }

        if(state.filters.onlyDiscounted && product.discount === 0){
            return false
        }

        if(state.filters.onlyNonDiscounted && product.discount > 0){
            return false
        }

        if(state.filters.stock !== 'all' && product.stock !== state.filters.stock){
            return false
        }

        if(
            state.filters.price.value !== null &&
            (
                product.price < state.filters.price.value[0] ||
                product.price > state.filters.price.value[1]
            )
        ){
            return false
        }

        return true
    })
}

export const saveCart = (cart: any, uniqueID: string)=> {
    localStorage.setItem(`${StorageCartKey}_${uniqueID}`, JSON.stringify(cart))
}


export const {
    setLastProductRef,
    toggleProductDialog,
    toggleInfoDialog,
    addProducts,
    addToCart,
    removeFromCart,
    clearFromCart,
    restoreCart,
    toggleCartOpen,

    setPriceFilter,
    setStockFilter,
    setMaxPrice,
    setSearchFilter,
    toggleDiscountedFilter,
    toggleNonDiscountedFilter,
    clearFilters
} = appSlice.actions
export default appSlice.reducer
import {Box, Card, CardContent, Dialog} from "@mui/material"
import {useAppDispatch, useAppState} from "../../../store/hooks"
import {toggleInfoDialog} from "../../../features/app"
import {ContactSeller, SidebarInfo} from "../../sidebar"
import 'react-slideshow-image/dist/styles.css'

const CatalogInfo = () => {
    
    const {isInfoOpen: isOpen} = useAppState((state) => state.app.gui)
    const { user, catalog } = useAppState((state)=> ({
        user: state.app.user?.value,
        catalog: state.app.catalog?.value
    }))
    const dispatcher = useAppDispatch()

    const closeDialog = () => {
        dispatcher(toggleInfoDialog())
    }

    return <Dialog
        open={isOpen}
        onClose={closeDialog}
    >
        {user && catalog ?
            <Card>
                <CardContent>
                    <SidebarInfo user={user} catalog={catalog} />
                    <Box paddingX={2} paddingY={1}>
                        <ContactSeller user={user}/>
                    </Box>
                </CardContent>
            </Card>
            : null
        }
    </Dialog>
}

export default CatalogInfo
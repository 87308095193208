import {Box, Chip} from "@mui/material";

type Props = {
    discount?:number
    position?: 'absolute'|'relative'|'static'
    top?: number
    right?:number
    size?:'small'|'medium'
}
const Discount = ({
    discount,
    top,
    right,
    position = 'absolute',
    size = 'small'
}:Props) => {

    if(!discount || discount <= 0) return null
    return <Box
        sx={{
            position,
            right: right ?? 10,
            top: top ?? 10,
            zIndex: 999
        }}
    >
        <Chip
            size={size}
            label={`-${discount}%`}
            color='success'
            sx={{color: 'white'}}
        />
    </Box>
}

export default Discount
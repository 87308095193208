import {Box, Typography} from "@mui/material";

type Props = {
    type: 'user'|'catalog'
}
const NotFound = ({type}:Props) => {

    return <Box sx={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center'
    }}>
        <img src={'/img/404v4.svg'} width={128}/>
        <Typography variant='h5' fontWeight='bold' color='white'>
            CATALOG NOT FOUND
        </Typography>
    </Box>
}

export default NotFound
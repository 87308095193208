import {CatalogCategory} from "../types";

export const calculatePrice = (price: number, discount: number) => {
    return discount > 0 ? (price - (price * discount / 100)) : (price)
}

export const getCategoryColor = (categoryCode: CatalogCategory) => {
    switch (categoryCode) {
        case "scholar":
        case "audio":
        case "room":
            return "#009ED4"
        case "tech":
        case "sport":
        case "shoes":
            return "#707070"
        case "food":
            return "#E0BE0B"
        case "cleaning":
        case "gift":
        case "plastic":
            return "#76C6E2"
        case "books":
        case "phones":
        case "bakery":
            return "#02B3A2"
        case "wine":
        case "jewelry":
        case "homeAppliances":
            return "#D95755"
        case "flowers":
            return "#8B5F33"
        case "eco":
        case "fruits":
        case "photo":
            return "#76AD93"
        case "party":
        case "tools":
        case "drugs":
        case "candy":
            return "#EFA94A"
        case "music":
        case "cloth":
            return "#925693"
        default:
            return "#925693"
    }
}

export const getCategoryIconName = (categoryCode: CatalogCategory) => {
    switch(categoryCode) {
        case "cleaning":
            return "0"
        case "tech":
            return "1"
        case "food":
            return "2"
        case "gift":
            return "3"
        case "scholar":
            return "4"
        case "books":
            return "5"
        case "phones":
            return "6"
        case "audio":
            return "7"
        case "room":
            return "8"
        case "sport":
            return "9"
        case "eco":
            return "10"
        case "party":
            return "11"
        case "flowers":
            return "12"
        case "tools":
            return "13"
        case "music":
            return "14"
        case "drugs":
            return "15"
        case "bakery":
            return "16"
        case "plastic":
            return "17"
        case "cloth":
            return "18"
        case "fruits":
            return "19"
        case "wine":
            return "20"
        case "candy":
            return "21"
        case "shoes":
            return "22"
        case "jewelry":
            return "23"
        case "photo":
            return "24"
        case "homeAppliances":
            return "25"
        default:
            return "26"
    }
}
import {Box, CircularProgress} from "@mui/material";
import {useEffect} from "react";
import {getImage} from "../../../features/app/extra-reducers";
import {useAppDispatch, useAppState} from "../../../store/hooks";


type Props = {
    userID:string,
    catalogID:string,
    productID:string,
    imageID:string,
    dummy?:boolean,
    height?:number,
    width?:number,
}
const Image = ({
    userID,
    catalogID,
    productID,
    imageID,
    dummy,
    height,
    width
}:Props) => {

    const imageData = useAppState((state) =>
        state.app.products.entities[productID]?.pictures[imageID]
    )
    const dispatcher = useAppDispatch()

    useEffect(() => {
        if(!imageData && !dummy){
            dispatcher(getImage({
                userID,
                catalogID,
                productID,
                imageID
            }))
        }
    }, [
        imageData, userID, catalogID,
        productID, imageID, dummy
    ])

    if(!imageData && !dummy) return <CircularProgress/>

    return <div className={'product-picture '+(dummy ? 'dummy-icon' : '')} style={{
        backgroundImage: `url('${ dummy ? '/design/single_product.svg':imageData.onlinePath}')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        boxSizing: 'border-box',
        height,
        width
    }}>
    </div>
}

export default Image
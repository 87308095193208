import {ListChildComponentProps} from "react-window";
import {Box, CircularProgress, Divider, IconButton, Typography} from "@mui/material";
import Cart from "../store/cart";
import {useGetProduct} from "../../features/app/hooks";
import {useParams} from "react-router-dom";
import Image from "../store/image";
import {memo, useMemo} from "react";
import {useAppDispatch, useAppState} from "../../store/hooks";
import {calculatePrice} from "../../util";
import {Close} from "@mui/icons-material";
import {clearFromCart, removeFromCart} from "../../features/app";
import {useTranslation} from "react-i18next";

const CartRow = ({ index, style, data }:ListChildComponentProps) => {

    const { t } = useTranslation()
    const { cartIndex, currency } = data
    const { userID, catalogID } =useParams()
    const { isLoading, product} = useGetProduct(cartIndex[index], userID, catalogID)
    const count = useAppState((state) => state.app.cart[cartIndex[index]])
    const dispatcher = useAppDispatch()


    const subTotal = useMemo(()=>{
        return count*calculatePrice(product?.price, product?.discount)
    }, [count, product])

    if(isLoading || !product) return <Box
        style={style}
        sx={{
            display: 'flex',
            justifyContent:'center',
            alignItems:'center'
        }}
    >
        <CircularProgress />
    </Box>

    if(!userID || !catalogID) return null

    const handleClear = (event: React.MouseEvent) => {
        dispatcher(clearFromCart(product.id))
    }

    return <Box
        style={style}
    >
        <Box
            sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '4px'
        }}
            >
            <Box>
                <Cart id={product?.id} stock={product?.stock} size='small' vertical />
            </Box>
            <Box minWidth={70} width={70} height={70}
                 sx={{
                     borderRadius: '0.5rem',
                     backgroundColor: 'var(--secondary-bgcolor)',
                     overflow: 'hidden'
                 }}
            >
                <Image
                    userID={userID}
                    catalogID={catalogID}
                    productID={product.id}
                    imageID={product.picturesRef[0]}
                    dummy={!Boolean(product.picturesRef[0])}
                    height={70} width={70}
                />
            </Box>
            <Box flexGrow={1} overflow='hidden'>
                <Typography
                    variant='body1'
                    sx={{
                        fontWeight: 'bold',
                        textWrap: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}
                >
                    {product?.name}
                </Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Box display='flex' flexDirection='column'>
                        <Typography
                            variant='caption'
                            color='primary.main'
                            fontWeight='bold'
                        >
                            {currency} {calculatePrice(product.price, product.discount).toFixed(2)}
                        </Typography>
                        <Typography
                            variant='caption'
                            color='text.secondary'
                            sx={{mt: -0.5}}
                        >
                            x {count} {t('cart.units')}
                        </Typography>
                    </Box>

                    <Typography variant='body2' fontWeight='bold'>
                        {currency} {subTotal.toFixed(2)}
                    </Typography>
                </Box>
            </Box>
            <Box>
                <IconButton onClick={handleClear}>
                    <Close sx={{fontSize: '12px'}} />
                </IconButton>
            </Box>
        </Box>
        { (cartIndex.length-1) !== index ?
            <Divider sx={{mt: 0.5}}/>:null
        }
    </Box>
}

export default memo(CartRow)
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth"
import { deleteAccountSchema, simpleFormDefaultValues, resetPasswordSchema } from "./form/schema"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
    Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField, CircularProgress
} from "@mui/material"

type ResetPasswordProps = {
    resetOpen:any
    toggleDialog:any
}
export const ResetPasswordDialog = ({ resetOpen, toggleDialog }:ResetPasswordProps) => {

    const { t } = useTranslation()
    const [submitting, toggleSubmit] = useState(false)
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        mode: 'onBlur',
        defaultValues: simpleFormDefaultValues,
        resolver: yupResolver(resetPasswordSchema)
    })

    const onSubmit = (data:any) => {
        const auth = getAuth()
        toggleSubmit(true)
        sendPasswordResetEmail(auth, data.email)
            .then(() => {
                console.log('Email sent')
                toast.success(t('reset_password.submit.success'))
                toggleSubmit(false)
                reset()
            })
            .catch((error) => {
                toast.error(t('reset_password.submit.error'))
                toggleSubmit(false)
                console.log(error)
            })
    }

    return <Dialog open={resetOpen} onClose={() => toggleDialog('reset')}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>{t('reset_password.title')}</DialogTitle>
            <DialogContent>
                <Typography>
                    {t('reset_password.description')}
                </Typography>

                <Controller name='email' control={control} render={({field: { value, onChange} }) => {
                    return <TextField
                        fullWidth
                        label={t('email_label')}
                        sx={{mt:2}}
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors.email)}
                        helperText={<>{errors.email?.message}</>}
                    />
                }} />

            </DialogContent>
            <DialogActions sx={{mx: 2, mb: 1}}>
                <Button type="submit" variant="contained" disabled={submitting}>
                    { submitting ? <CircularProgress sx={{width: '14px !important', height: '14px !important'}} />:null }
                    {t('send')}
                </Button>
                <Button color="secondary" variant="contained" onClick={() => { reset();toggleDialog('reset')}}>
                    {t('cancel')}
                </Button>
            </DialogActions>
        </form>
    </Dialog>
}

type DeleteAccountProps = {
    deleteOpen:any
    toggleDialog:any
}
export const DeleteAccountDialog = ({ deleteOpen, toggleDialog }:DeleteAccountProps) => {

    const { t } = useTranslation()
    const [submitting, toggleSubmit] = useState(false)
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        mode: 'onBlur',
        defaultValues: simpleFormDefaultValues,
        resolver: yupResolver(deleteAccountSchema)
    })

    const onSubmit = (data:any) => {
        const auth = getAuth()
        toggleSubmit(true)
        signInWithEmailAndPassword(auth, data.email, data.password).then((userCredential) => {
            // Signed in
            const user = userCredential.user
            user.delete().then(() => {
                // User deleted.
                toast.loading(t('delete_dialog.submit.success'), { icon: <CheckCircleIcon /> })
                toggleSubmit(false)
                reset()
                toggleDialog('delete')
            }).catch((error) => {
                // An error happened.
                console.log(error)
                toast.error(t('delete_dialog.submit.error.default'))
                toggleSubmit(false)
            });
        }).catch((error) => {
            const errorCode = error.code
            toggleSubmit(false)
            switch(errorCode){
                case 'auth/user-not-found':
                    return toast.error(t('delete_dialog.submit.error.user_password_not_found'))
                case 'auth/wrong-password':
                    return toast.error(t('delete_dialog.submit.error.user_password_not_found'))
                default:
                    return toast.error(t('delete_dialog.submit.error.default'))

            }
        });

    }

    return <Dialog open={deleteOpen} onClose={() => toggleDialog('delete')}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>
                {t('delete_dialog.title')}
            </DialogTitle>
            <DialogContent>
                <Typography>
                    {t('delete_dialog.description')}
                </Typography>

                <Controller name='email' control={control} render={({field: { value, onChange} }) => {
                    return <TextField
                        fullWidth
                        label={t('email_label')}
                        sx={{mt:2}}
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors.email)}
                        helperText={<>{errors.email?.message}</>}
                    />
                }} />

                <Controller name='password' control={control} render={({field: { value, onChange} }) => {
                    return <TextField
                        fullWidth
                        label={t('password_label')}
                        type="password"
                        sx={{mt:2}}
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors.email)}
                        helperText={<>{errors.email?.message}</>}
                    />
                }} />

            </DialogContent>
            <DialogActions sx={{px: 2, pb: 1}}>
                <Button type="submit" variant="contained" color="error" disabled={submitting}>
                    { submitting ? <CircularProgress sx={{width: '14px !important', height: '14px !important'}} />:null }
                    {t('delete')}
                </Button>
                <Button color="primary" variant="contained" onClick={() => {reset();toggleDialog('delete')}}>
                    {t('cancel')}
                </Button>
            </DialogActions>
        </form>
    </Dialog>
}
import {Box, Divider, Drawer, IconButton, Typography} from "@mui/material";
import {useAppDispatch, useAppState} from "../../store/hooks";
import {toggleCartOpen} from "../../features/app";
import ShoppingBag from "@mui/icons-material/ShoppingBagTwoTone";
import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {Close, WhatsApp} from "@mui/icons-material";
import {FixedSizeList} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import CartRow from "./cartrow";
import {calculatePrice} from "../../util";

const Cart = ()=> {
    const { t } = useTranslation()
    const isOpen = useAppState((state) => state.app.gui.isCartOpen)
    const catalogOwner = useAppState((state) => state.app.user?.value)
    const { catalogCurrency, catalogName } = useAppState((state) => ({
        catalogCurrency: state.app.catalog?.value?.currency,
        catalogName: state.app.catalog?.value?.name
    }))
    const products = useAppState((state) => state.app.products.entities)

    const cart = useAppState((state) => state.app.cart)
    const cartIndex = useMemo(()=> {
        return Object.keys(cart)
    }, [cart])

    const cartSize = useMemo(()=> {
        let total = 0
        for(const id of Object.keys(cart)){
            total += cart[id]
        }
        return total
    }, [cart])

    const cartTotal = useMemo(()=>{
        let total = 0
        for(const id of Object.keys(cart)){
            total += cart[id]*calculatePrice(products[id]?.price, products[id]?.discount)
        }
        return total.toFixed(2)
    }, [cart, products])

    const requestMsg = useMemo(()=>{
        let msg = `${t('cart.request.header')} *${catalogName}*!\n`
        msg += `${t('cart.request.list_present')} \n`
        for(const id of cartIndex){
            const prod = products[id]
            msg += `- ${prod?.name} x ${cart[id]}\n`
        }
        msg += `\n${t('cart.request.footer')}`

        return encodeURIComponent(msg)
    }, [t, catalogName, products, cart, cartIndex])

    const dispatcher = useAppDispatch()
    const closeCart = ()=> {
        dispatcher(toggleCartOpen(false))
    }

    const handleRequest = () => {

    }

    return <Drawer
        anchor='right'
        open={isOpen}
        onClose={closeCart}
    >
        <Box display='flex' flexDirection='column' minWidth={350} height='100%'>
            <Box display='flex' flexDirection='row' alignItems='center' p={2}>
                <ShoppingBag />
                &nbsp;
                <Typography>
                    {cartSize} {t('products')}
                </Typography>
                <Box flexGrow={1} />
                <IconButton onClick={closeCart}>
                    <Close />
                </IconButton>
            </Box>
            <Divider/>
            <Box flexGrow={1} p={2}>
                { cartSize > 0 ?
                    <AutoSizer>
                        {({height, width}:any)=>
                            <FixedSizeList
                                height={height}
                                itemCount={cartIndex.length}
                                width={width}
                                itemSize={90}
                                itemData={{
                                    cartIndex,
                                    currency: catalogCurrency
                                }}
                            >
                                {CartRow}
                            </FixedSizeList>
                        }
                    </AutoSizer>
                :
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                    }}>
                        <img src={'/img/shopping_bag.svg'} width={120} alt={t('cart.empty')} />
                        <Typography variant='h6'>
                            {t('cart.empty')}
                        </Typography>
                    </Box>
                }
            </Box>
            <Divider sx={{mb: 1}} />
            { cartSize > 0 ?
                <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    component='a'
                    href={`https://api.whatsapp.com/send?phone=${catalogOwner?.cpp}${catalogOwner?.telefono}&text=${requestMsg}`}
                    target='_blank'
                    m={2}
                    sx={{
                        padding: 1,
                        borderRadius: '1rem',
                        bgcolor: catalogOwner?.wp ? 'primary.main' : 'action.disabled',
                        pointerEvents: catalogOwner?.wp ? 'all':'none',
                        cursor: 'pointer',
                        '&:hover': {
                            bgcolor: 'primary.dark'
                        },
                        textDecoration: 'none',
                        color: 'text.primary',

                    }}
                >
                    <Box display='flex' flexDirection='row' alignItems='center' pl={2}>
                        <WhatsApp sx={{color: 'white'}} />
                        <Typography
                            sx={{
                                color: 'white',
                                ml: 1,
                                lineHeight: 'unset'
                            }}
                        >
                            {t('cart.send')}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            padding: 1,
                            borderRadius: '0.5rem',
                            bgcolor: 'white'
                        }}
                    >
                        <Typography>
                            {catalogCurrency} {cartTotal}
                        </Typography>
                    </Box>
                </Box>:null
            }
        </Box>
    </Drawer>
}

export default Cart
export type CatalogCategory = "other"
    | "cleaning"
    | "tech"
    | "food"
    | "gift"
    | "scholar"
    | "books"
    | "phones"
    | "audio"
    | "room"
    | "sport"
    | "eco"
    | "party"
    | "flowers"
    | "tools"
    | "music"
    | "drugs"
    | "bakery"
    | "plastic"
    | "cloth"
    | "fruits"
    | "wine"
    | "candy"
    | "shoes"
    | "jewelry"
    | "photo"
    | "homeAppliances"

export type Catalog = {
    name: string
    description: string
    onlinePath?: string
    creatorID: string
    currency: string
    isFavStr: string
    color: string
    totalProducts: number
    uniqueID:string
    categoryCode: CatalogCategory
}

export type User = {
    correo: string
    nombre: string
    ig_user?: string
    fb_user?: string
    wp: boolean
    cpp: number
    telefono?: number
    userID:string
}

export type Picture = {
    isCover: boolean
    path: string
    onlinePath?: string
}

export type Stock = 'available'|'ondemand'|'ask'|'transit'|'soldout'

export type Product = {
    id:string
    name: string
    position: number
    price: number
    stock: Stock
    isFavorite: boolean
    description: string
    discount: number
    picturesRef: string[]
    pictures: { [key:string]: Picture }
}

type StockColorType = {
    [key: string]: "default" | "success" | "primary" | "secondary" | "error" | "info" | "warning" | undefined
}
export const StockColor: StockColorType = {
    'available': 'primary',
    'ondemand': 'warning',
    'transit': 'info',
    'soldout': 'error'
}
import { Box, styled } from "@mui/material"
import { CSSProperties } from "react"
import { MoonLoader } from "react-spinners"

const LoaderBox = styled(Box)(() => ({
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
}))

const logoStyle:CSSProperties = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)'
}

const Ca7Loader = () => {
    return <LoaderBox>
        <MoonLoader size={128} color="#b0b0b0" speedMultiplier={0.5} />
        <img src="/logo96.png" style={logoStyle} />
    </LoaderBox>
}

export default Ca7Loader
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAYvIMsvHzPFgiqIhUd4LFZ8qByD8Qn4ko",
    authDomain: "catalogo-42fc6.firebaseapp.com",
    databaseURL: "https://catalogo-42fc6.firebaseio.com",
    projectId: "catalogo-42fc6",
    storageBucket: "catalogo-42fc6.appspot.com",
    messagingSenderId: "217072183559",
    appId: "1:217072183559:web:e5ca9f5ac3f6ec46b2bda2"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app
import {Box, Button, CircularProgress, Divider, Paper} from "@mui/material"
import { useParams } from "react-router-dom"
import InfoRow from "../inforow"
import FollowUs from "../followus"
import StoreHeader from "../store/storeheader"
import {useGetCatalogData, useGetUserData} from "../../features/app/hooks";
import {useTranslation} from "react-i18next";
import Filters from "./filters";
import ShoppingBag from "@mui/icons-material/ShoppingBagTwoTone";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {useAppDispatch, useAppState} from "../../store/hooks";
import {useMemo} from "react";
import {toggleCartOpen} from "../../features/app";
import CatalogInfo from "../dialogs/cataloginfo";
import {Catalog, User} from "../../types";
import NotFound from "../404";

type Props = {
    expanded?:boolean
}

export const ContactSeller = ({user}:{user:User}) => {
    const { t } = useTranslation()

    return <Button
        fullWidth
        disabled={!user.wp}
        variant='outlined'
        color='success'
        target='_blank'
        href={`https://api.whatsapp.com/send?phone=${user.cpp}${user.telefono}&text=${t('contact_msg')}`}
        startIcon={<WhatsAppIcon/>}
    >{t('contact')}</Button>
}
export const SidebarInfo = ({user, catalog, expanded}:{user:User, catalog:Catalog, expanded?:boolean}) => {
    const { t } = useTranslation()

    return <Box display='flex' flexDirection='column' flexGrow={1} padding={2} gap={2.5}>
        <InfoRow title={t('name')} content={user.nombre} />
        {!expanded ?
            <InfoRow title={t('description')} content={catalog.description} />:null
        }
        <InfoRow title={t('email')} content={user.correo} />
        {user.cpp && user.telefono 
            ? <InfoRow title={t('phone')} content={`(+${user.cpp}) ${user.telefono}`} />
            : null
        }
        <Box display='flex' flexGrow={1} />
        <FollowUs fb={user.fb_user} ig={user.ig_user} />
    </Box>
}
const Sidebar = ({ expanded }:Props) => {

    const { userID, catalogID } = useParams()
    const { t } = useTranslation()
    const dispatcher = useAppDispatch()

    const { isLoading:catalogLoading, isError: catalogError, catalog } = useGetCatalogData(userID, catalogID)
    const { isLoading:userLoading, isError: userError, user  } = useGetUserData(userID)

    const cart = useAppState((state) => state.app.cart)
    const cartSize = useMemo(
        ()=> {
            let total = 0
            for(const id of Object.keys(cart)){
                total += cart[id]
            }
            return total
        },
        [cart])

    if(catalogLoading||userLoading) return <CircularProgress />
    if(userError||catalogError) return <NotFound type='catalog' />
    if(!user) return null
    if(!catalog) return null


    if(!expanded){
        return <Paper elevation={4} sx={{ width: '100%', display: 'flex', flexDirection: 'column'}}>
            <StoreHeader catalog={catalog} sx={{ p: 1 }} />
            <Divider />
            <CatalogInfo />
        </Paper>
    }

    const openCart = () => {
        dispatcher(toggleCartOpen(true))
    }

    return <Paper 
        elevation={4} 
        sx={{ 
            height: '100%', 
            width: '260px', 
            display: 'flex', 
            flexDirection: 'column',
            overflowY: 'auto'
        }}
    >
        <StoreHeader catalog={catalog} sx={{ p: 2 }} expanded />
        <Divider />
        <Filters />
        <Divider />
        <Box paddingX={2} paddingY={1}>
            <Button
                fullWidth
                color='warning'
                variant='outlined'
                disableElevation
                startIcon={<ShoppingBag />}
                sx={{mb:0.5}}
                onClick={openCart}
            >{t('cart.open')} ({cartSize})</Button>
            <ContactSeller user={user}/>
        </Box>
        <Divider />
        <SidebarInfo expanded user={user} catalog={catalog} />
    </Paper>
}

export default Sidebar
import {
    Box, Card, CardContent, CardHeader, Paper, Typography,
    Grid
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { DeleteAccountDialog, ResetPasswordDialog } from "./dialogs";

const DeleteAccount = () => {

    const [resetOpen, toggleResetDialog] = useState(false)
    const [deleteOpen, toggleDeleteDialog] = useState(false)
    const { t } = useTranslation()

    const toggleDialog = (type:any) => {
        if(type === 'reset'){
            toggleResetDialog(prev => !prev)
        }else{
            toggleDeleteDialog(prev => !prev)
        }
    }

    return <Paper sx={{width: '100%', height: '100%', backgroundColor: 'var(--primary-color)'}}>

        <ResetPasswordDialog resetOpen={resetOpen} toggleDialog={toggleDialog} />
        <DeleteAccountDialog deleteOpen={deleteOpen} toggleDialog={toggleDialog} />
        <Box display='flex' margin='auto' height='100%' maxWidth={1200}>
            <Grid container margin='auto'>
                <Grid item xs={12} sm={3} md={4}
                      sx={{
                          display: 'flex',
                          justifyContent: { xs: 'center', sm: 'end'},
                          alignItems: { xs: 'center', sm: 'baseline'}
                      }}
                      display='flex' justifyContent='end' alignItems='baseline'
                >
                    <img src={'/logo512.png'} alt="Logo" width={192} height={192} />
                </Grid>
                <Grid item xs={12} sm={9} md={6} sx={{padding: '20px'}}>
                    <Card>
                        <CardHeader title={t('delete_account.title')} />
                        <CardContent>
                            <Typography>
                                {t('delete_account.alert')}
                            </Typography>
                            <br/>
                            <Typography fontSize='20px'>
                                {t('delete_account.instr.how')}
                            </Typography>
                            <ol style={{marginTop: 0}}>
                                <li>
                                    {t('delete_account.instr.browser.1')} <a href="#" onClick={() => toggleDialog('reset')}>{t('here')}</a>.
                                </li>
                                <li>{t('delete_account.instr.browser.2')} <a href="#" onClick={() => toggleDialog('delete')}>{t('here')}</a>.</li>
                            </ol>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    </Paper>
}

export default DeleteAccount
import {Box, Button, ButtonGroup, Chip, IconButton, Tooltip} from "@mui/material";
import {MouseEventHandler, useState} from "react";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppState} from "../../../store/hooks";
import {addToCart, removeFromCart, toggleCartOpen, toggleProductDialog} from "../../../features/app";
import {Stock, StockColor} from "../../../types";


type Props = {
    id:string
    size?: 'small'|'medium'|'large',
    variant?:  "text" | "outlined" | "contained"
    justify?: 'start'|'center'|'end',
    stock: Stock,
    hideOnRunOut?:boolean,
    showOpenCartBtn?: boolean,
    vertical?:boolean
}
const Cart = ({
    id,
    size,
    variant,
    stock,
    justify = 'start',
    hideOnRunOut,
    showOpenCartBtn,
    vertical
}:Props) => {
    const { t } = useTranslation()
    const count = useAppState((state) => state.app.cart[id])
    const wpEnabled = useAppState((state) => state.app.user?.value?.wp)

    const dispatcher = useAppDispatch()
    const handleCart = (event: React.MouseEvent) => {
        event.stopPropagation()
        dispatcher(addToCart(id))
    }

    const handleIncrease = (event: React.MouseEvent) => {
        if(vertical){
            event.stopPropagation()
            dispatcher(removeFromCart(id))
        }else{
            handleCart(event)
        }
    }

    const handleDecrease = (event: React.MouseEvent) => {
        if(vertical){
            handleCart(event)
        }else{
            event.stopPropagation()
            dispatcher(removeFromCart(id))
        }
    }

    const openCart = () => {
        dispatcher(toggleProductDialog(undefined))
        dispatcher(toggleCartOpen(true))
    }

    if(['soldout','transit'].includes(stock) && hideOnRunOut) return null

    if(['soldout','transit'].includes(stock)) return <Box display='flex' justifyContent={justify}>
        <Chip label={t(`stock.${stock}.label`)} color={StockColor[stock]} />
    </Box>

    return <Box display='flex' justifyContent={justify}>
        {count > 0 ?
            <ButtonGroup
                disabled={!wpEnabled}
                orientation={ vertical ? 'vertical':'horizontal'}
            >
                <Button
                    size={size}
                    variant={variant}
                    sx={{minWidth: '0 !important', padding: vertical ? 0:''}}
                    onClick={handleDecrease}
                >
                    { vertical ? '+':'-'}
                </Button>
                <Button
                    size={size}
                    variant={variant}
                    sx={{minWidth: vertical ? '0 !important':'', padding: vertical ? 0.5:''}}
                >
                    {count < 10 ? `0${count}`: count}
                </Button>
                <Button
                    size={size}
                    variant={variant}
                    sx={{minWidth: '0 !important', padding: vertical ? 0:''}}
                    onClick={handleIncrease}
                >
                    { vertical ? '-':'+'}
                </Button>
            </ButtonGroup>
        :
            <Button
                size={size}
                variant={variant}
                color='primary'
                disableElevation
                onClick={handleCart}
                disabled={!wpEnabled}
                startIcon={
                    <ShoppingBasketIcon />
                }
            >
                {t(size === 'large' ? 'add_cart':'add_cart_short')}
            </Button>
        }

        {showOpenCartBtn ?
            <Tooltip title={t('cart.open')}>
                <IconButton 
                    onClick={openCart}
                    color='primary'
                    sx={{ml: 1}}
                >
                    <ShoppingBasketIcon />
                </IconButton>
            </Tooltip>
            :null
        }
    </Box>
}

export default Cart
import { Avatar, Box, IconButton, SxProps, Typography } from "@mui/material"
import { Catalog } from "../../../types"
import InfoIcon from '@mui/icons-material/Info';
import {Search} from "@mui/icons-material";
import {useMemo, useState} from "react";
import Filters from "../../sidebar/filters";
import {useAppDispatch} from "../../../store/hooks";
import {clearFilters, toggleInfoDialog} from "../../../features/app";
import {useTranslation} from "react-i18next";
import {getCategoryColor, getCategoryIconName} from "../../../util";

type Props = {
    catalog:Catalog
    expanded?:boolean
    sx:SxProps
}

const StoreHeader = ({sx, catalog, expanded}:Props) => {

    const { t } = useTranslation()
    const [showFilters, toggleFilters] = useState(false)
    const dispatcher = useAppDispatch()
    const avatarSize = expanded ? 80:50

    const cover = useMemo(()=>{
        if(catalog.categoryCode === 'other' && catalog.onlinePath){
            return {
                url: catalog.onlinePath,
                color: catalog.color
            }
        }else{
            return {
                url: `/design/category/${getCategoryIconName(catalog.categoryCode)}.svg`,
                color: getCategoryColor(catalog.categoryCode)
            }
        }
    }, [catalog])

    const onToggleFilters = () => {
        if(showFilters){
            toggleFilters(false)
            dispatcher(clearFilters())
        }else {
            toggleFilters(true)
        }
    }

    const onToggleInfo = () => {
        dispatcher(toggleInfoDialog())
    }

    if(!expanded && showFilters){
        return <Box sx={sx}>
            <Filters onBack={onToggleFilters} />
        </Box>
    }

    return <Box sx={sx}>
        <Box display='flex' alignItems='center'>
            <Box sx={{
                mr: 1,
                boxSizing: 'border-box',
                border: '6px double',
                overflow: 'hidden',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                width: avatarSize,
                height: avatarSize,
                minWidth: avatarSize,
                minHeight: avatarSize,
            }}>
                <Avatar sx={{
                    width: avatarSize,
                    height: avatarSize,
                    backgroundColor: cover.color,
                    backgroundImage: `url("${cover.url}")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}>
                    { catalog.onlinePath 
                        ? <img src={""} height={avatarSize} />
                        : ' '
                    }
                </Avatar>
            </Box>
            <Box flexGrow={1}>
                <Typography variant="h6">
                    {catalog.name}
                </Typography>
                <Typography variant="caption">
                    <strong>{catalog.totalProducts}</strong> {t('products')}
                </Typography>
            </Box>
            { !expanded ?
                <Box display='flex' flexDirection='row'>
                    <IconButton onClick={onToggleFilters}>
                        <Search />
                    </IconButton>
                    <IconButton onClick={onToggleInfo}>
                        <InfoIcon />
                    </IconButton>
                </Box>
                :null
            }
        </Box>
        { expanded ?
            <Box pt={1}>
                <Typography variant="body2">
                    {catalog.description}
                </Typography>
            </Box>
            :null
        }
    </Box>
}

export default StoreHeader;
import ShoppingBag from "@mui/icons-material/ShoppingBagTwoTone";
import {Badge, Box, Paper} from "@mui/material";
import {useAppDispatch, useAppState} from "../../store/hooks";
import {useMemo} from "react";
import {toggleCartOpen} from "../../features/app";


const CartPreview = () => {
    const cart = useAppState((state) => state.app.cart)
    const dispatcher = useAppDispatch()

    const cartSize = useMemo(()=> {
        let total = 0
        for(const id of Object.keys(cart)){
            total += cart[id]
        }
        return total
    }, [cart])

    const openCart = () => {
        dispatcher(toggleCartOpen(true))
    }

    return <Paper
        elevation={4}
        onClick={openCart}
        sx={{
            position: 'absolute',
            top: '50%',
            left: 0,
            transform: 'translateY(-50%)',
            bgcolor: 'white',
            paddingLeft: 1,
            paddingY: 2,
            paddingRight: cartSize > 0 ? 2: 1,
            borderRadius: '0 0.5rem 0.5rem 0'
        }}
    >
        <Badge badgeContent={cartSize} color='warning'>
            <ShoppingBag color='warning' />
        </Badge>
    </Paper>
}

export default CartPreview
import {Box, Typography} from "@mui/material";
import { useTranslation } from "react-i18next";


const GetIt = () => {

    const { t } = useTranslation()

    return <Box
        sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
        }}
    >
        <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            textAlign='center'
        >
            <div>
                <img src={'logo512.png'} alt='Catálogo App' width={164}/>
            </div>
            <div>
                <a href='https://play.google.com/store/apps/details?id=com.s7even.software.catalogo&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img
                        height={64}
                        alt='Disponibile su Google Play'
                        src={`https://play.google.com/intl/en_us/badges/static/images/badges/${t('lang')}_badge_web_generic.png`}
                    />
                </a>
            </div>
        </Box>
    </Box>
}

export default GetIt
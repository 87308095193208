import { Box, IconButton, Typography } from "@mui/material"
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import {useTranslation} from "react-i18next";




type Props = {
    ig?: string
    fb?: string
}
const FollowUs = ({ig, fb}:Props) => {

    const { t } = useTranslation()

    if(!ig && !fb) return null
    
    return <Box>
        <Box>
            <Typography variant="caption">
                {t('follow')}
            </Typography>
        </Box>
        <Box display='flex'>
        { ig 
            ? <IconButton target='_blank' href={`https://www.instagram.com/${ig}`}>
                <InstagramIcon />
            </IconButton>
            : null
        }
        &nbsp;
        { fb
            ? <IconButton target='_blank' href={`https://www.fb.com/${fb}`}>
                <FacebookIcon />
            </IconButton>
            : null
        }   
        </Box>
    </Box>
}

export default FollowUs
import * as yup from 'yup'

export const simpleFormDefaultValues = { email: '', password: '' }

export const resetPasswordSchema = yup.object().shape({
    email: yup.string().email('Invalid email').required()
})

export const deleteAccountSchema = yup.object().shape({
    email: yup.string().email('Invalid email').required(),
    password: yup.string().required('Password is required')
})
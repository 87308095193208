import { Box, Typography } from "@mui/material"

type Props = {
    title: string
    content: string
}
const InfoRow = ({title, content}:Props) => {

    return <Box>
        <Typography variant="body2" fontWeight='bold'>{title}</Typography>
        <Typography variant="body2" mt='2px'>{content}</Typography>
    </Box>
}

export default InfoRow
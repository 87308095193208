import {Box, IconButton, MenuItem, Slider, Switch, TextField, Tooltip, Typography} from "@mui/material";
import {useAppDispatch, useAppState} from "../../../store/hooks";
import {
    setPriceFilter,
    setSearchFilter,
    setStockFilter,
    toggleDiscountedFilter,
    toggleNonDiscountedFilter
} from "../../../features/app";
import {useMemo, useRef, useState} from "react";
import {StockColor} from "../../../types";
import {useTranslation} from "react-i18next";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import {ArrowBack} from "@mui/icons-material";

const StockMenu = Object.keys(StockColor)
type Props = {
    onBack?: (event:any) => void
}
const Filters = ({onBack}:Props) => {

    const [showFilters, toggleFilters] = useState(false)
    const dispatcher = useAppDispatch()
    const { t } = useTranslation()

    const searchChangeDelay = useRef<any|null>(null)

    const {
        search,
        stock,
        onlyDiscounted, onlyNonDiscounted,
        price: { minPrice, maxPrice, value:priceRange}
    } = useAppState((state) => state.app.filters)
    const catalogCurrency = useAppState((state) => state.app.catalog?.value?.currency)
    const [localSearch, setSearch] = useState(search)

    const marks = useMemo(()=>{
        return [
            {
                value: 0,
                label: `${catalogCurrency} 0`,
            },
            {
                value: maxPrice,
                label: `${catalogCurrency} ${maxPrice}`,
            },
        ]

    },[maxPrice, catalogCurrency])


    const step = useMemo(()=>{
        if(maxPrice != 0){
            return maxPrice/100
        }
        return 1
    },[maxPrice])

    const toggleFilter = () => {
        toggleFilters((prev) => !prev)
    }

    const handleSearch = (event:any) => {
        setSearch(event.target.value)
        clearTimeout(searchChangeDelay.current)
        searchChangeDelay.current = setTimeout(
            ()=> dispatcher(setSearchFilter(event.target.value)),
            500
        )
    }

    const handleStockChange = (event:any)=> {
        dispatcher(setStockFilter(event.target.value))
    }

    const handlePriceRange = (_event:any, value:number|number[], _activeThumb:number)=>{
        dispatcher(setPriceFilter(value))
    }

    const onToggleDiscounted = (event: any) => {
        dispatcher(toggleDiscountedFilter(event.target.checked))
    }

    const onToggleNonDiscounted = (event: any) => {
        dispatcher(toggleNonDiscountedFilter(event.target.checked))
    }

    return <Box sx={{transition: '0.3s'}}>
        <Box
            display='flex'
            flexDirection='row'
            alignItems='end'
            paddingX={ onBack ? 0:2}
            my={1}
        >
            { onBack ?
                <IconButton onClick={onBack}>
                    <ArrowBack/>
                </IconButton> : null
            }
            <TextField
                fullWidth
                value={localSearch}
                onChange={handleSearch}
                InputProps={{
                    startAdornment: <SearchRoundedIcon/>
                }}
                size='small'
            />
            <IconButton sx={{ml:0.5}} onClick={toggleFilter}>
                <Tooltip placement='top' title={t( showFilters ? 'filter.hide':'filter.show')}>
                    <TuneRoundedIcon/>
                </Tooltip>
            </IconButton>
        </Box>

        {showFilters ?
            <>
                <Box paddingX={2} mb={1}>
                    <TextField
                        value={stock ?? 'all'}
                        select
                        fullWidth
                        size='small'
                        onChange={handleStockChange}
                    >
                        <MenuItem key={'all'} value={'all'}>
                            {t(`stock.all.label`)}
                        </MenuItem>
                        {
                            StockMenu.map((stock) => {
                                return <MenuItem key={stock} value={stock}>
                                    {t(`stock.${stock}.label`)}
                                </MenuItem>
                            })
                        }
                    </TextField>
                </Box>


                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    paddingLeft={2}
                    mx={2} mb={1}
                    border={1}
                    borderColor={'grey.400'}
                    borderRadius={'4px'}
                >
                    <Typography>
                        {t('filter.discounted')}
                    </Typography>
                    <Switch checked={onlyDiscounted} color="primary" onChange={onToggleDiscounted} />
                </Box>

                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    paddingLeft={2}
                    mx={2}
                    mb={1}
                    border={1}
                    borderColor={'grey.400'}
                    borderRadius={'4px'}
                >
                    <Typography>
                        {t('filter.nondiscounted')}
                    </Typography>
                    <Switch checked={onlyNonDiscounted} color="primary" onChange={onToggleNonDiscounted} />
                </Box>

                <Box
                    mx={2}
                    mb={1}
                    border={1}
                    pt={1}
                    borderColor={'grey.400'}
                    borderRadius={'4px'}
                >
                    <Box>
                        <Typography paddingX={2}>
                            {t('filter.price')}
                        </Typography>
                    </Box>
                    <Box
                        paddingX={3}
                    >
                        <Slider
                            value={priceRange ?? [0,0]}
                            step={step}
                            min={minPrice}
                            max={maxPrice}
                            marks={marks}
                            valueLabelDisplay="auto"
                            onChange={handlePriceRange}
                        />
                    </Box>
                </Box>

            </>
            :null
        }
    </Box>
}

export default Filters